import React, { useState } from 'react';
import { FormWrapper } from './Form.style';

const Form = ({ data }) => {
  const [formData, setFormData] = useState({});

  let form = data
    .match(/<script.*?>([\s\S]*?)<\/script>/gim)[0]
    .match(/(form\.fields=)\[(.*)(\])/gim)[0]
    .replace('form.fields=', '');
  form = JSON.parse(form);

  const submit = e => {
    e.preventDefault();
  };

  return (
    <FormWrapper>
      <form onSubmit={e => submit(e)}>
        {form.map((field, i) => {
          switch (field.type) {
            case 'listselect':
              return (
                <div key={field.key} className="fieldgroup">
                  <label>
                    {field.label}
                    {field.required ? <sup>*</sup> : ''}
                  </label>
                  <select
                    onChange={e =>
                      setFormData(...formData, {
                        [field.label]: e.target.value,
                      })
                    }
                  >
                    {field.options.map((y, j) => (
                      <option key={`${field.key}_child_${j}`} value={y.value}>
                        {y.label}
                      </option>
                    ))}
                  </select>
                </div>
              );

            case 'firstname':
            case 'lastname':
            case 'textbox':
              return (
                <div key={field.key} className="fieldgroup">
                  <label>
                    {field.label}
                    {field.required ? <sup>*</sup> : ''}
                  </label>
                  <input
                    required={field.required}
                    name={field.label}
                    onChange={e =>
                      setFormData(...formData, {
                        [field.label]: e.target.value,
                      })
                    }
                    type="text"
                  />
                </div>
              );

            case 'phone':
              return (
                <div key={field.key} className="fieldgroup">
                  <label>
                    {field.label}
                    {field.required ? <sup>*</sup> : ''}
                  </label>
                  <input
                    required={field.required}
                    name={field.label}
                    onChange={e =>
                      setFormData(...formData, {
                        [field.label]: e.target.value,
                      })
                    }
                    type="phone"
                  />
                </div>
              );

            case 'email':
              return (
                <div key={field.key} className="fieldgroup">
                  <label>
                    {field.label}
                    {field.required ? <sup>*</sup> : ''}
                  </label>
                  <input
                    required={field.required}
                    name={field.label}
                    onChange={e =>
                      setFormData(...formData, {
                        [field.label]: e.target.value,
                      })
                    }
                    type="email"
                  />
                </div>
              );

            // case 'address':
            // case 'city':
            //   return (
            //     <div key={field.key} className="fieldgroup">
            //       <label>
            //         {field.label}
            //         {field.required ? <sup>*</sup> : ''}
            //       </label>
            //       <Geosuggest
            //         onSuggestSelect={e =>
            //           setFormData(...formData, { [field.label]: e.target.value })
            //         }
            //         placeholder="Begin met typen om te zoeken..."
            //       />
            //     </div>
            //   );

            case 'textarea':
              return (
                <div key={field.key} className="fieldgroup">
                  <label>
                    {field.label}
                    {field.required ? <sup>*</sup> : ''}
                  </label>
                  <textarea
                    onChange={e =>
                      setFormData(...formData, {
                        [field.label]: e.target.value,
                      })
                    }
                    cols="30"
                    rows="10"
                  />
                </div>
              );

            case 'html':
              return (
                <div key={field.key} className="fieldgroup">
                  <label>{field.label}</label>
                  <div dangerouslySetInnerHTML={{ __html: field.value }} />
                </div>
              );

            case 'date':
              return (
                <div key={field.key} className="fieldgroup">
                  <label>
                    {field.label}
                    {field.required ? <sup>*</sup> : ''}
                  </label>
                  <input
                    required={field.required}
                    placeholder="09/05/1994"
                    onChange={e =>
                      setFormData(...formData, {
                        [field.label]: e.target.value,
                      })
                    }
                  />
                </div>
              );

            case 'hr':
              return <hr key={field.key} />;

            // case 'checkbox':
            //   if (!this.state.formInput[i])
            //     this.setFormItem(i, field.label, false);
            //   return (
            //     <label
            //       key={field.key}
            //       className="fieldgroup fieldgroup--checkbox"
            //     >
            //       <input
            //         type="checkbox"
            //         onChange={e =>
            //           setFormData(...formData, {
            //             [field.label]: e.target.value,
            //           })
            //         }
            //       />
            //       <span>{field.label}</span>
            //     </label>
            //   );

            // case 'listradio':
            //   return (
            //     <div key={field.key} className="fieldgroup fieldgroup--radio">
            //       <p>
            //         {field.label}
            //         {field.required ? <sup>*</sup> : ''}
            //       </p>
            //       {field.options.map((y, i) => (
            //         <label
            //           key={`${field.key}-radioitem-${i}`}
            //           className="radio-item"
            //         >
            //           <input
            //             type="radio"
            //             value={y.label}
            //             name="unique"
            //             onChange={e =>
            //               setFormData(...formData, {
            //                 [field.label]: e.target.value,
            //               })
            //             }
            //           />
            //           {y.label}
            //         </label>
            //       ))}
            //     </div>
            //   );

            case 'submit':
              return (
                <input
                  key={field.key}
                  className="button primary"
                  type="submit"
                  value={field.label}
                />
              );

            // case 'hidden':
            //   if (field.label === 'mailto' && !this.state.formInput.mailto) {
            //     setFormData(...formData, {
            //       mailto: field.value,
            //     });
            //   }
            //   break;

            default:
              console.error(`Can't find ${field.type}`);
              return <p key={field.key}>Ongeldig veld</p>;
          }

          return false;
        })}
      </form>
    </FormWrapper>
  );
};

export default Form;
