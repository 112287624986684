import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/Seo/Seo';
import Form from '../components/Form/Form';
import SocialShare from '../components/SocialShare/SocialShare';
import Layout from '../layout';
import Modular from './modular';

const Page = ({
  data: {
    wordpressPage: { acf, content, title },
  },
  location,
}) => (
  <Layout>
    <SEO title={title} />
    <Modular modules={acf && acf.modules_page} />
    {content.indexOf('ninja-forms-noscript-message') > -1 && (
      <Form data={content} />
    )}
    <SocialShare url={location.href} title={title} />
  </Layout>
);

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      title
      content
      acf {
        modules_page {
          ... on WordPressAcf_header {
            id
            title
            style
            meta
            rubrieken
            quote
            bg {
              source_url
            }
            bg_backdrop {
              source_url
            }
          }

          ... on WordPressAcf_intro {
            text
            style
          }

          ... on WordPressAcf_paragraph {
            text
            background {
              source_url
            }
          }

          ... on WordPressAcf_context_image {
            id
            context {
              label
              waarde
            }
            image {
              alt_text
              source_url
            }
          }

          ... on WordPressAcf_image {
            id
            grootte
            image {
              alt_text
              source_url
            }
          }

          ... on WordPressAcf_quote {
            id
            text
          }
        }
      }
    }
  }
`;

export default Page;
